import { useEffect } from 'react'
import Navigation from '../layouts/Navigation'
import Footer from '../layouts/Footer'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Imprint({ loader, loading}) {
    const { t } = useTranslation();
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    return (
        <>
            {loading && loader}
            <div>
                <Navigation {...{subMenu: false, boolean: true}} />
                <main id='main' className='main'>
                    <section className='privacy__wrapper'>
                        <div className='privacy__container'>
                            <h1 className='terms__heading'>{t('impressum.heading')}</h1>
                            <h3 className='terms__heading3'>{t('impressum.requiredInfo')}</h3>
                            <h3 className='terms__heading3'>{t('impressum.responsible')}</h3>
                            <h3 className='terms__heading3'>SigmaHeat GmbH</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.managedBy')}</span>
                                <span>– Maik Brinkmann</span>
                                <br />
                                <span>{t('privacy.phone')} +49 511 87453680</span>
                                <span>{t('privacy.email')} <a href='mailto:info@sigmaheat.de'>info@sigmaheat.de</a></span>
                                <span>{t('privacy.website')} <a href='https://www.sigmaheat.de' target='_blank' rel='noreferrer'>sigmaheat.de</a></span>
                                <br />
                                <span>Georgsplatz 54</span>
                                <span>30159 Hannover</span>
                            </p>

                            <h3 className='terms__heading3'>{t('impressum.authorities')}</h3>
                            <p className='terms__paragraph'>
                            <span>{t('impressum.registerCourt')}: Amtsgericht Hannover</span>
                            <span>{t('impressum.tradeRegisterNumber')}: 221918</span>
                            <span>{t('impressum.vatId')}: DE 343603805</span>
                            </p>

                            <h3 className='terms__heading3'>{t('impressum.responsibleContent')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('impressum.responsibleWebsite')}</span>
                            </p>

                            <h3 className='terms__heading3'>SigmaHeat GmbH</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.managedBy')}</span>
                                <span>– Maik Brinkmann</span>
                            </p>

                            <h3 className='terms__heading3'>{t('impressum.disputeResolution')}</h3>
                            <p className='terms__paragraph'>
                                <span dangerouslySetInnerHTML={{ __html: t('impressum.euCommissionText') }} />
                                <br />
                                <span>{t('impressum.internalDisputeResolution')}</span>
                            </p>

                            <h3 className='terms__heading3'>SigmaHeat GmbH</h3>
                            <p className='terms__paragraph'>
                                <span>Lange Laube 2</span>
                                <span>30159 Hannover</span>
                            </p>

                            <h3 className='terms__heading3'>{t('impressum.copyRight')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('impressum.copyRightContent')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('impressum.disclaimer.heading')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('impressum.disclaimer.contentAndLink')}</span>
                                <span>{t('impressum.disclaimer.liability')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('impressum.privacy')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('impressum.privacyPolicyLink')} <Link to='/privacy-policy'>{t('impressum.here')}</Link></span>
                            </p>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </>
    )
}

export default Imprint