import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CookieBanner from "./CookieBanner";
import Logo from "../assets/imgs/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import NavigationDropDown from "./NavigationDropDown";

function Navigation({ subMenu, boolean = false }) {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [jobs, setJobs] = useState(0);
  const [display, setDisplay] = useState(false);
  // const [display__second, setDisplay__second] = useState(false);
  const navigation = useRef(null);
  const mainMenu = useRef(null);
  const location = useLocation();
  const dropBTN = useRef();
  // const dropBTN__second = useRef();
  const dropContent = useRef();
  // const dropContent__second = useRef();

  const mainNavigationList = [
    { name: t("top_navigation.start"), to: "/mission" },
    {
      type: "dropdown",
      name: t("top_navigation.products.title"),
      elements: [
        {
          type: "dropdown",
          name: t("top_navigation.propertyOwners.title"),
          elements: [
            {
              name: t("top_navigation.propertyOwners.dropdown", {
                returnObjects: true,
              })[0],
              to: "/products/monitoring_optimierung",
            },
            {
              name: t("top_navigation.propertyOwners.dropdown", {
                returnObjects: true,
              })[1],
              to: "/products/hydraulischer_abgleich",
            },
          ],
        },

        // {
        //   type: "dropdown",
        //   name: t("top_navigation.craft.title"),
        //   elements: [
        //     {
        //       name: t("top_navigation.craft.dropdown", {
        //         returnObjects: true,
        //       })[0],
        //       to: "/products/test-kit",
        //     },
        //   ],
        // },
        {
          name: t("top_navigation.products.dropdown", {
            returnObjects: true,
          })[2],
          to: "/beta-test",
        },
        {
          name: t("top_navigation.products.dropdown", {
            returnObjects: true,
          })[3],
          to: "/products/academy",
        },
      ],
    },
    { name: t("top_navigation.blog"), to: "/blog" },
    // { name: t("applyToJob"), to: "/jobs" },
  ];

  const subNavigationList = [
    { name: t("navigation.manifesto"), to: "/the-band-manifesto" },
    { name: t("navigation.workFromAnywhere"), to: "/work-from-anywhere" },
    { name: t("navigation.growWithUs"), to: "/grow-with-us" },
    { name: t("navigation.jobCategories"), to: "/job-categories" },
  ];

  const fetchJobs = () => {
    const url = "https://sigmaheat.jobs.personio.de/xml";
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        const xml = this.responseXML;
        setJobs(Array.from(xml.getElementsByTagName("position")).length);
      }
    };
    xhttp.open("GET", url);
    xhttp.send();
  };

  const toggleScrollbar = (enable) => {
    document.documentElement.style.overflow = enable ? "unset" : "hidden";
  };

  const toggleDropdownDisplay = useCallback(() => {
    if (window.innerWidth <= 1024) {
      const currentDisplay = dropContent.current?.style.display;
      const newDisplay = currentDisplay === "block" ? "none" : "block";
      dropContent.current.style.display = newDisplay;
      setDisplay(newDisplay === "block");
    }
  }, [dropContent.current]);

  const outsideClickEvent = useCallback(
    (e) => {
      if (
        window.innerWidth <= 1024 &&
        dropBTN.current &&
        // dropBTN__second.current &&
        dropContent.current &&
        // dropContent__second.current &&
        !dropBTN.current.contains(e.target) &&
        // !dropBTN__second.current.contains(e.target) &&
        !dropContent.current.contains(e.target) 
        // &&
        // !dropContent__second.current.contains(e.target)
      ) {
        dropContent.current.style.display = "none";
        // dropContent__second.current.style.display = "none";
        setDisplay(false);
        // setDisplay__second(false);
      }
    },
    [
      dropBTN.current,
      // dropBTN__second.current,
      dropContent.current,
      // dropContent__second.current,
    ]
  );

  const resizeEvent = useCallback(() => {
    if (window.innerWidth > 1024) setDisplay();

    if (
      dropContent.current &&
      // dropContent__second.current &&
      window.innerWidth > 1024
    ) {
      dropContent.current.style.display = "";
      // dropContent__second.current.style.display = "";
    }
  // }, [dropContent.current, dropBTN__second.current]);
}, [dropContent.current]);


  useEffect(() => {
    fetchJobs();
    document.addEventListener("click", function (event) {
      outsideClickEvent(event);
    });
    window.addEventListener("resize", function (event) {
      resizeEvent(event);
    });

    return () => {
      document.removeEventListener("click", function (event) {
        outsideClickEvent(event);
      });
      window.removeEventListener("resize", function (event) {
        resizeEvent(event);
      });
    };
  }, []);

  useEffect(() => {
    boolean
      ? document.querySelector(".navbar").classList.add("dark")
      : document.querySelector(".navbar").classList.remove("dark");
  }, [boolean]);

  useEffect(() => {
    if (localStorage.getItem("sigmaheatCookies") === null)
      toggleScrollbar(location.pathname === "/privacy-policy");
  }, [location.pathname]);

  return (
    <>
      <nav className="navbar" ref={navigation}>
        <section className="navbar__main__menu" ref={mainMenu}>
          <div className="img__logo__container">
            <Link to={"/"}>
              <img className="img__logo" src={Logo} alt="" />
            </Link>
          </div>

          <div className={`navbar__mobile${openMenu ? " open" : ""}`}>
            <ul
              className={
                openMenu
                  ? "navbar__menu__list"
                  : "navbar__menu__list navbar__menu__list_width"
              }
            >
              {mainNavigationList.map((link, i) => {
                if ("elements" in link)
                  return (
                    <>
                      <li
                        className="dropbtn"
                        key={`main-navigation-${link.name}`}
                      >
                        <div className="dropdown-links">
                          <Link
                            className="navbar__menu__item navbar__menu__item__height "
                            to={"/#"}
                            onClick={(e) => e.preventDefault()}
                          >
                            {link.name}
                          </Link>
                          <FontAwesomeIcon
                            className={display ? "rotate__icon" : ""}
                            icon={faArrowDown}
                            ref={dropBTN}
                            onClick={() => toggleDropdownDisplay()}
                          />
                        </div>
                        <div className="dropdown-content" ref={dropContent}>
                          {link.elements.map((elem, i) => {
                            if ("elements" in elem) {
                              return (
                                <div
                                  className="dropbtn dropbtn-second"
                                  key={`main-navigation-${elem.name}`}
                                >
                                  {/* <div className="dropdown-links-second">
                                    <Link
                                      className="navbar__menu__item"
                                      to={"/#"}
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      {elem.name}
                                    </Link>
                                    <FontAwesomeIcon
                                      icon={
                                        display ? faArrowDown : faArrowRight
                                      }
                                      className={
                                        display__second && faArrowDown
                                          ? "rotate__icon"
                                          : ""
                                      }
                                      ref={dropBTN__second}
                                      onClick={() =>
                                        toggleDropdownDisplay__second()
                                      }
                                    />
                                  </div> */}
                                  <NavigationDropDown elements={elem} display={display}/>
                                  {/* <div
                                    className="dropdown-content dropdown-content-second"
                                    ref={dropContent__second}
                                  >
                                    {elem.elements.map((subElem, i) => (
                                      <Link
                                        to={subElem.to}
                                        key={`element-key-${i}`}
                                      >
                                        {subElem.name}
                                      </Link>
                                    ))}
                                  </div> */}
                                </div>
                              );
                            }
                            return (
                              <Link
                                to={elem.to}
                                key={`element-key-${i}`}
                                className="navbar__menu__item"
                              >
                                {elem.name}
                              </Link>
                            );
                          })}
                        </div>
                      </li>
                    </>
                  );
                return (
                  <li key={`main-navigation-${link.to}`}>
                    <Link className={`navbar__menu__item ${link.name === "Jetzt bewerben" ? "orange text-shadow font-size-change" : ""}`} to={link.to}>
                      {link.name}
                    </Link>
                  </li>
                );
              })}
              {/* <Link
                className="navbar__menu__item "
                to={"/partnering"}
                style={{ minWidth: "120px", textAlign: "center" }}
              >
                {t("be_a_partner")}
              </Link> */}
              {/* <Link className="navbar__menu__item" to={"/app"} style={{ minWidth: "120px", textAlign: "center" }}>{t('to_the_app')}</Link> */}
              <Link className="navbar__menu__item" to={"/jobs"}>
                {t("allJobs")} <span>{jobs}</span>
              </Link>
            </ul>
          </div>
          <Link
            className="navbar__menu__item"
            to={"/jobs"}
          >
            {t("applyNow")}
          </Link>
          {/* <Link className="navbar__menu__item" to={"/app"} style={{ minWidth: "120px", textAlign: "center" }}>{t('to_the_app')}</Link> */}
          <button
            className={`navbar__trigger${openMenu ? " open" : ""}`}
            onClick={() => setOpenMenu(!openMenu)}
          >
            <span className="hamburger__line"></span>
            <span className="hamburger__line"></span>
            <span className="hamburger__line"></span>
          </button>
        </section>
        {subMenu && (
          <section className="navbar__submenu">
            <ul className="navbar__menu__list">
              {subNavigationList.map((link) => (
                <li key={`sub-navigation-${link.to}`}>
                  <Link className="navbar__menu__item" to={link.to}>
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </section>
        )}
      </nav>
      {location.pathname !== "/privacy-policy" && <CookieBanner />}
    </>
  );
}

export default Navigation;
