import { useEffect } from 'react'
import Navigation from '../layouts/Navigation'
import JobLayout from '../layouts/JobLayout'
import Footer from '../layouts/Footer'
import { useLocation } from 'react-router-dom'
import Newsletter from '../layouts/Newsletter'

function Job({ loader, loading}) {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    
    return (
        <>
            {loading && loader}
            <div>
                <Navigation {...{subMenu: false, boolean: true}} />
                <main id="main" className="main">
                    <div className='background__wrapper'>
                        <div className='background__image' />
                        <div className='padding__wrapper'>
                            <JobLayout />
                        </div>
                    </div>
                </main>
                <Footer />

                <Newsletter />
            </div>
        </>
    )
}

export default Job