import { useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faMapLocation, faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
// import { faFacebook, faInstagram, faTwitter, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import Navigation from '../layouts/Navigation'
import Footer from '../layouts/Footer'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import confetti from 'canvas-confetti'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Cookies from 'js-cookie'
import Newsletter from '../layouts/Newsletter'

import { Form } from "react-bootstrap";

function Contact({ loader, loading, portalId="25048456", formId="56465729-61c6-4b3b-bf83-4037cd4b0488" }) {
    const [cookie, setCookie] = useState(true);
    // const [accept, setAccept] = useState(false);

    const queryParameters = new URLSearchParams(window.location.search)
    const msg_content = queryParameters.get("message")

    // const [FuECentralisation, setFuECentralisation] = useState(msg_content === 'Zentralisierungsprojekt');
    let location = useLocation();
    // const navigate = useNavigate();
    let { t } = useTranslation();


    // console.log("accept", accept);
    // console.log("fue", FuECentralisation);
    const contactOptions = [
        {
            icon: faAddressCard,
            title: t('contact.chatTitle'),
            description: t('contact.chatDescription'),
            href: 'mailto:info@sigmaheat.de',
            anchortag: 'info@sigmaheat.de'
        }, {
            icon: faMapLocation,
            title: t('contact.addressTitle'),
            description: t('contact.addressDescription'),
            href: '#',
            anchortag: 'Walderseestraße 7, 30163 Hannover'
        }, {
            icon: faPhoneVolume,
            title: t('contact.phoneTitle'),
            description: t('contact.phoneDescription'),
            href: 'tel:+4951187453680',
            anchortag: '0511 87 45 36 80'
        }
    ]

    // const confettiJam = () => {
    //     var myConfetti = confetti.create(document.querySelector('canvas'), {
    //         resize: true,
    //         useWorker: true
    //     });

    //     var end = Date.now() + (5 * 1000);
    //     var colors = ['#1ABC9C', '#2ECC71', '#3498DB', '#9B59B6', '#F1C40F', '#E67E22', '#E74C3C'];
    //     (function frame() {
    //         myConfetti({
    //             particleCount: 3,
    //             angle: 60,
    //             spread: 55,
    //             origin: { x: 0 },
    //             colors: colors
    //         });
    //         myConfetti({
    //             particleCount: 3,
    //             angle: 120,
    //             spread: 55,
    //             origin: { x: 1 },
    //             colors: colors
    //         });
    //         if (Date.now() < end) requestAnimationFrame(frame);
    //     }());
    // }

    // const onSubmit = (e, accept) => {
    //     if (!accept) {
    //         const MySwal = withReactContent(Swal)
    //         MySwal.fire({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: t('contact.privacyPolicyMSG')
    //         })
    //         return;
    //     }
    //     let msg = {
    //         "name": e.target[0].value,
    //         "mail": e.target[1].value,
    //         "text": e.target[2].value,
    //         "fue-centralisation": FuECentralisation,
    //         "dsgvo": accept,
    //     }


        // ONLY POST max. 10 DICT-Elements for security - else the dict will not be saved as raw datagram - less or equal then 10 will be saved
        // fetch("https://tech.sigmaheat.de/contact", {
        //     method: "POST",
        //     body: JSON.stringify(msg)
        // }).then(data => {
        //     if (data.status === 202) {
        //         confettiJam();
        //         let timerInterval;
        //         const MySwal = withReactContent(Swal)
        //         MySwal.fire({
        //             icon: 'success',
        //             title: 'Hurray...',
        //             text: t('successMSG'),
        //             html: `<p id="countdown">${t('contact.redirected')}<span>5</span></p>`,
        //             timer: 5000,
        //             didOpen: () => {
        //                 Swal.showLoading()
        //                 timerInterval = setInterval(() => {
        //                     Swal.getHtmlContainer().querySelector('span')
        //                         .textContent = (Swal.getTimerLeft() / 1000)
        //                             .toFixed(0)
        //                 }, 100)
        //             },
        //             willClose: () => {
        //                 setCookie(true)
        //                 clearInterval(timerInterval)
        //                 navigate('/')
        //                 const expirationDate = new Date(Date.now() + 5 * 60 * 1000);
        //                 Cookies.set('name', 'value', { expires: expirationDate });
        //             }
        //         })
        //     } else {
        //         const MySwal = withReactContent(Swal)
        //         MySwal.fire({
        //             icon: 'error',
        //             title: 'Oops...',
        //             text: t('contact.errorMSG')
        //         })
        //     }
        // })
    // }

    //HubspotContactForm
    // useEffect(() => {
    //     const script = document.createElement("script");
    //     script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
    //     script.type = "text/javascript";
    //     // script.charset="utf-8"
    //     document.body.appendChild(script);
    
    //     script.addEventListener("load", () => {
    //       if (window.hbspt) {
    //         window.hbspt.forms.create({
    //           target: "#contactForm",
    //           region: "eu1",
    //           portalId: "25048456",
    //           formId: "56465729-61c6-4b3b-bf83-4037cd4b0488",
    
    //           onFormSubmit: function ($form) {
    //             window.sessionStorage.setItem("submit", true);
    //           },
    //         });
    //       }
    //     });
    //   }, []);

    const formFieldsToHSJSON = (form) => {
        let fieldArray = [];
        let formData = new FormData(form);
        for (let field of formData) {
            let values = {
                "name": field[0],
                "value": field[1]
            }
            fieldArray.push(values)
        }
        return fieldArray;
    }

    const getCookie = (name) => {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    };

    const missingKey = (array) => {
        const keys = ['company', 'city', 'email', '0-2/anzahl_we', 'legalconsent'];

        const foundKeys = new Set();
    
        for (let i = 0; i < array.length; i++) {
            const obj = array[i];
            foundKeys.add(obj.name);
            if (typeof obj.value === 'string' && obj.value.trim() === '') return true;
        }
    
        for (let i = 0; i < keys.length; i++) {
            if (!foundKeys.has(keys[i])) return true;
        }

        return false;
    }

    const onSubmit = useCallback((e) => {
        e.preventDefault();

        var form = document.querySelector('form')
        let obj = formFieldsToHSJSON(form)

        if (missingKey(obj)) {
            const MySwal = withReactContent(Swal)
      
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Stellen Sie sicher, dass alle erforderlichen Felder ausgefüllt sind, damit Sie zum nächsten Schritt übergehen können!'
            })
      
            return false;
        }

        var data = {
            "submittedAt": Date.now(),
            "fields": obj,
            "context": {
                "hutk": getCookie('hubspotutk'),
                "pageUri": window.location.href,
                "pageName": document.title
            },
            "legalConsentOptions": {
                "consent": {
                    "consentToProcess": true,
                    "text": "Ich stimme zu, andere Benachrichtigungen von SigmaHeat GmbH zu erhalten."
                }
            }
        }

        fetch(`https://forms-eu1.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin', 
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data),
        })
        .then(response => {
            if (!response.ok) return {}
            return response.json()
        })
        .then(data => {
            const MySwal = withReactContent(Swal)
      
            MySwal.fire({
                icon: 'success',
                title: 'Wir freuen uns auf Ihre Kontaktaufnahme!',
                text: 'Vielen Dank, dass Sie sich mit uns in Verbindung setzen möchten! 🌟 Wir freuen uns sehr darüber, dass Sie Kontakt aufnehmen möchten. Ihre Nachricht bedeutet uns viel, und wir können es kaum erwarten, mit Ihnen in Kontakt zu treten. Wir werden unser Bestes tun, um schnellstmöglich zu antworten. In der Zwischenzeit können Sie gerne mehr über unser Angebot auf unserer Website erfahren. Bis bald!'
            })
        });
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    useEffect(() => {
        setCookie(Cookies.get('name') === undefined ? false : true)
    }, [])

    return (
        <>
            {loading && loader}
            <div>
                <Navigation {...{ subMenu: false, boolean: true }} />
                <canvas id="container" style={{ position: 'absolute', width: '100%', height: '100vh', zIndex: 5, pointerEvents: 'none' }}></canvas>
                <main id="main" className="main">
                    <div className='background__wrapper'>
                        <div className='background__image' />
                        <div className='padding__wrapper'>

                            <section className='contact__wrapper'>
                                <div className='contact__container'>
                                    <div className='contact__left__col'>
                                        <div className='contact__options'>
                                            {contactOptions.map((o, i) => {
                                                return (
                                                    <div className='contact__option' key={`contact-key-${i}`}>
                                                        <FontAwesomeIcon icon={o.icon} />
                                                        <div>
                                                            <h4 className='contact__option__title'>{o.title}</h4>
                                                            <p className='contact__option__description'>{o.description}</p>
                                                        </div>
                                                        <a className='contact__option__link' href={o.href}>{o.anchortag}</a>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        {/* <div className='contact__social__media'>
                                            <a href='/#'><FontAwesomeIcon icon={faFacebook} /></a>
                                            <a href='/#'><FontAwesomeIcon icon={faInstagram} /></a>
                                            <a href='/#'><FontAwesomeIcon icon={faTwitter} /></a>
                                            <a href='/#'><FontAwesomeIcon icon={faLinkedin} /></a>
                                            <a href='/#'><FontAwesomeIcon icon={faYoutube} /></a>
                                        </div> */}
                                    </div>

                                    {/* <div id="contactForm" /> */}
                                    <div>
                                        <Form onSubmit={(e) => onSubmit(e)}>
                                            <Form.Group className="mb-3" controlId="email">
                                                <Form.Label>E-Mail</Form.Label>
                                                <Form.Control type="email" name="email"  placeholder="" required />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="company">
                                                <Form.Label>Unternehmensname</Form.Label>
                                                <Form.Control type="text" name="company"  placeholder="" required/>
                                            </Form.Group>
                                            
                                            <Form.Group className="mb-3" controlId="0-2/anzahl_we">
                                                <Form.Label>Anzahl Wohneinheiten (WE)</Form.Label>
                                                <Form.Control type="number" name="0-2/anzahl_we"  placeholder="" required />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="city">
                                                <Form.Label>Stadt</Form.Label>
                                                <Form.Control type="text" name="city"  placeholder="" required />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="firstname">
                                                <Form.Label>Vorname</Form.Label>
                                                <Form.Control type="text" name="firstname"  placeholder="" required />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="lastname">
                                                <Form.Label>Nachname</Form.Label>
                                                <Form.Control type="text" name="lastname"  placeholder="" required />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="phone">
                                                <Form.Label>Telefonnummer</Form.Label>
                                                <Form.Control type="tel" name="phone"  placeholder="" />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="message">
                                                <Form.Label>Nachricht</Form.Label>
                                                <Form.Control as="textarea" name="message"  rows={3} />
                                            </Form.Group>

                                            <label className='mb-3'>SigmaHeat GmbH verpflichtet sich, Ihre Privatsphäre zu schützen und zu respektieren. Wir nutzen Ihre persönlichen Daten nur zur Verwaltung Ihres Kontos und zur Bereitstellung der von Ihnen angeforderten Produkte und Dienstleistungen. Von Zeit zu Zeit möchten wir Sie über unsere Produkte und Dienstleistungen sowie andere Inhalte, die für Sie von Interesse sein könnten, informieren. Wenn Sie damit einverstanden sind, dass wir Sie zu diesem Zweck kontaktieren, geben Sie bitte unten an, wie Sie von uns kontaktiert werden möchten:</label>

                                            <Form.Group className="mb-3" controlId="legalconsent">
                                                <Form.Check type="checkbox" label="Ich stimme zu, andere Benachrichtigungen von SigmaHeat GmbH zu erhalten." name="legalconsent" />
                                            </Form.Group>

                                            <label>Sie können diese Benachrichtigungen jederzeit abbestellen. Weitere Informationen zum Abbestellen, zu unseren Datenschutzverfahren und dazu, wie wir Ihre Privatsphäre schützen und respektieren, finden Sie in unserer Datenschutzrichtlinie.</label>
                                            <label>Indem Sie unten auf „Einsenden“ klicken, stimmen Sie zu, dass SigmaHeat GmbH die oben angegebenen persönlichen Daten speichert und verarbeitet, um Ihnen die angeforderten Inhalte bereitzustellen.</label>

                                            <div className="d-flex justify-content-end">
                                                <button className="contact__button" type="submit">Einsenden</button>
                                            </div>
                                        </Form>
                                    </div>

                                    {/* <form className='contact__right__col' onSubmit={(e) => { e.preventDefault(); onSubmit(e, accept); }}>
                                        <div className='contact__top__wrapper'>
                                            <h1 className='contact__heading'>{t('contact.heading')}</h1>
                                            <p className='contact__description'>{t('contact.description')}</p>
                                        </div>

                                        <div className='contact__ul'>
                                            <input className='contact__input' id="your-name" name="your-name" type='text' placeholder={t('contact.name')} required={true} />
                                            <input className='contact__input' id="email" name="email" type='text' placeholder={t('contact.mail')} required={true} />
                                            <textarea className='contact__textarea' id="tell-us" name="tell-us" placeholder={(msg_content !== undefined) ? msg_content : t('contact.placeholder')} required={true} rows="10" />
                                        </div> */}

                                        {/* <span>
                                            <input type="checkbox" id="fue-centralisation" name="fue-centralisation" checked={FuECentralisation} onChange={(e) => { console.log("click"); setFuECentralisation(!FuECentralisation)}} />
                                            <label htmlFor="fue-centralisation">{
                                            "Teilnahmeanfrage zum FuE-Projekt \"Zentralisierung Etagenheizung-MFH\""}<br/>{"(Infos folgen 09/23)"
                                            }</label>
                                        </span> */}
                                        {/* <div className="contact__control__wrapper">

                                            <span>
                                                <input type="checkbox" id="privacy-policy" name="privacy-policy" onChange={(e) => setAccept(e.target.checked)} />
                                                <label htmlFor="privacy-policy">{t('privacyPolicyConfirmation')} <Link to='/privacy-policy' target="_blank" rel="noreferrer">{t('privacyPolicyLink')}</Link>{t('privacyPolicyConfirmationPartTwo')}</label>
                                            </span>

                                            <input className='locations__button' type='submit' disabled={cookie} value={t('contact.submit')} />
                                        </div>
                                    </form> */}

                                </div>
                            </section>

                        </div>
                    </div>
                </main>
                <Footer />

                <Newsletter />
            </div>
        </>
    )
}

export default Contact