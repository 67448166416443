import { useEffect, useRef } from 'react'
import StackedCard from '../components/StackedCard'

function StackedCards({ stackedCards }) {
    const stackedCardsReferal = useRef(null);
    function stackedCardsEffect() {
        const stackedCards = [...stackedCardsReferal.current.querySelectorAll(':scope > div')].filter((e, i) => i % 3 === 1);
        const stackedCardsRef = [...stackedCardsReferal.current.querySelectorAll(':scope > div')].filter((e, i) => i % 3 === 2);
        const stackedCardsLinks = stackedCardsReferal.current.querySelectorAll(':scope > a');
        

        stackedCardsRef.forEach((item, i) => {
            item.style = `position: sticky; display: flex; flex-direction: column; align-items: center; min-height: calc(100vh - ${stackedCardsRef.length / 2 * stackedCardsLinks[i].clientHeight}px); max-height: calc(100vh - ${stackedCardsRef.length * stackedCardsLinks[i].clientHeight}px); top: min((100% - 610px) - ${(stackedCardsRef.length - i - 1) * stackedCardsLinks[i].clientHeight}px, ${(i + 1) * stackedCardsLinks[i].clientHeight}px); margin-bottom: calc(${(i + 1) * -stackedCardsLinks[i].clientHeight}px); background: #ffffff80;`;
        });

        if (stackedCardsLinks[stackedCards.length - 1].getBoundingClientRect().top <= stackedCardsLinks[1].clientHeight * (stackedCards.length - 1)) {
            for (let i = 0; i < stackedCards.length; i++) {
                stackedCards[i].style = `position: relative; margin-top: ${i * stackedCardsLinks[i].clientHeight}px; box-shadow: rgb(255, 255, 255) 0px 39.5px 0px 39.5px; transform: translate(0px, ${-stackedCardsRef[i].clientHeight * i + stackedCardsRef[i].clientHeight * (stackedCards.length - 1)}px);`;
                stackedCardsLinks[i].style = `position: relative; z-index: 1; margin-top: ${i * stackedCardsLinks[i].clientHeight}px; transform: translate(0px, ${-stackedCardsRef[i].clientHeight * i + stackedCardsRef[i].clientHeight * (stackedCards.length - 1)}px);`;
            }
        } else {
            for (let i = 0; i < stackedCards.length; i++) {
                stackedCards[i].style = `position: sticky; display: none; width: 100%; height: 0; margin-top: ${i * stackedCardsLinks[i].clientHeight}px; box-shadow: rgb(255, 255, 255) 0px 39.5px 0px 39.5px; top: calc(${i * stackedCardsLinks[i].clientHeight}px); bottom: calc(${(stackedCards.length - i - 1) * stackedCardsLinks[i].clientHeight}px);`;
                stackedCardsLinks[i].style = `position: sticky; z-index: 1; margin-top: ${i * stackedCardsLinks[i].clientHeight}px; top: calc(${i * stackedCardsLinks[i].clientHeight}px); bottom: calc(${(stackedCards.length - i - 1) * stackedCardsLinks[i].clientHeight}px);`;
            }
        }

        stackedCardsReferal.current.style.paddingBottom = `${stackedCards.length * stackedCardsLinks[stackedCards.length - 1].clientHeight + (stackedCards.length - 2) * stackedCardsLinks[0].clientHeight}px`;
    }

    // useEffect(() => {
    //     stackedCardsEffect();
    //     window.addEventListener('resize', stackedCardsEffect);
    //     window.addEventListener('scroll', stackedCardsEffect);
    //     return () => {
    //         window.removeEventListener('resize', stackedCardsEffect);
    //         window.removeEventListener('scroll', stackedCardsEffect);
    //     }
    // }, [])

    return (
        <section className="stacked__cards" ref={stackedCardsReferal}>
            {stackedCards.map(card => <StackedCard key={`stackedcard-${card.id}`} {...card}/>)}
        </section>
    )
}

export default StackedCards
