import { useEffect } from 'react'
// import Accordion from '../components/Accordion'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Heated from '../assets/imgs/heated.png'

function Footer() {
    const { t } = useTranslation();

    // const communitiesList = [
    //     {name: t('design'), to: '/design'},
    //     {name: t('developers'), to: '/developers'},
    //     {name: t('brands'), to: '/brands'},
    //     {name: t('artists'), to: '/artists'},
    //     {name: t('engineering'), to: '/engineering'},
    //     {name: t('research'), to: '/research'}
    // ];

    // const stayUpdatedList = [
    //     {name: t('latest'), to: '/lastest'},
    //     {name: t('instagram'), to: '/instagram'},
    //     {name: t('linkedin'), to: '/linkedin'},
    //     {name: t('twitter'), to: '/twitter'},
    //     {name: t('youtube'), to: '/youtube'},
    //     {name: t('podcast'), to: '/podcast'}
    // ];

    // const careerList = [
    //     {name: t('locations'), to: '/locations'},
    //     {name: t('beingHere'), to: '/being-here'},
    //     {name: t('students'), to: '/students'},
    //     {name: t('howWeHire'), to: '/how-we-hire'},
    //     {name: t('allJobs'), to: '/all-jobs'}
    // ];

    // const diversityList = [
    //     {name: t('mentalHealth'), to: '/mental-health'},
    //     {name: t('diversityEquityInclusionBelonging'), to: '/diversity'},
    //     {name: t('socialImpact'), to: '/social-impact'},
    //     {name: t('climateAction'), to: '/climate-action'}
    // ];

    const bottomLeftList = [
        { name: t('sigmaHeat'), to: '/' },
        // { name: t('terms'), to: '/terms' },
        { name: t('imprint'), to: '/imprint' },
        { name: t('privacyPolicy'), to: '/privacy-policy' },
    ];


    // function footerSticky() {
    //     const elementRect = document.getElementById("main").clientHeight;
    //     document.querySelector(".footer__pusher").style.height = `${document.querySelector(".footer").clientHeight}px`;

    //     if (elementRect <= window.scrollY + window.innerHeight + 40) {
    //         document.querySelector(".footer").style.zIndex = "1";
    //     } else {
    //         document.querySelector(".footer").style.zIndex = "-1";
    //     }

    //     if (elementRect <= window.scrollY) {
    //         document.querySelector(".footer").classList.remove("fixed");
    //     } else {
    //         document.querySelector(".footer").classList.add("fixed");
    //     }
    // }

    // useEffect(() => {
    //     footerSticky();
    //     window.addEventListener('resize', footerSticky);
    //     window.addEventListener('scroll', footerSticky);
    //     window.addEventListener('footerResize', footerSticky);

    //     return () => {
    //         window.removeEventListener('resize', footerSticky);
    //         window.removeEventListener('scroll', footerSticky);
    //         window.removeEventListener('footerResize', footerSticky);
    //     }
    // }, [])

    return (
        <>
            <footer className="footer">
                <div className="main__container">
                    {/* <div className="pre__footer">
                        <img className='footer__icon' src={Heated} alt='' />

                        <div className="pre__footer__box">
                            <h2 className="pre__footer__heading">{t('heatedEnough')}</h2>
                            <p className="pre__footer__paragraph"></p>
                        </div>
                        <div className="pre__footer__buttons">
                            <Link className="pre__footer__button" to='https://meetings-eu1.hubspot.com/maik-brinkmann/maik-brinkmann'>{t('DirectLiveDemo')}</Link>
                            <Link className="pre__footer__button" to='/contact'>{t('orToTheContactForm')}</Link>
                            <Link className="pre__footer__button" to='/jobs'>{t('toTheJobs')}</Link>

                            <Link className="pre__footer__button" to={"how-we-hire"}>{t('howWeHire')}</Link>
                            <Link className="pre__footer__button" to={"read-our-faq"}>{t('readOurFaq')}</Link>
                        </div>
                    </div> */}

                    {/* <div className="footer__menu">
                        <div className="footer__menu__col">
                            <Accordion title={t('communities')} array={communitiesList} />
                        </div>

                        <div className="footer__menu__col">
                            <Accordion title={t('stayUpdated')} array={stayUpdatedList} />
                        </div>

                        <div className="footer__menu__col">
                            <Accordion title={t('career')} array={careerList} />
                        </div>

                        <div className="footer__menu__col">
                            <Accordion title={t('diversity')} array={diversityList} />
                        </div>
                    </div> */}

                    <div className="footer__bottom">
                        <ul className="footer__bottom__list">
                            {bottomLeftList.map(link => <li key={`bottomleft-navigation-${link.to}`}><Link className='footer__menu__link' to={link.to}>{link.name}</Link></li>)}
                        </ul>
                    </div>
                </div>
            </footer>

            <div className="footer__pusher"></div>
        </>
    )
}

export default Footer