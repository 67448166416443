import React from 'react'
import { ClientsSlider } from '../components/SwiperSlider'
import { useTranslation } from 'react-i18next';
import Gundlach from '../assets/clients/gundlach.png'
import Meravis from '../assets/clients/meravis.png'
import Energie from '../assets/clients/energie.png'
import Niedersachsenministerium from '../assets/clients/niedersachsenministerium.png'
import Bausie from '../assets/clients/bausie.png'
import Ostland from '../assets/clients/ostland.png'
import VdW from '../assets/clients/vdw.png'
import DRK from '../assets/clients/DRK.svg'

function ClientsSection() {
    const { t } = useTranslation();

    const slides = [
        // { img: Energie, client: 'die Energieingenieure' },
        { img: Gundlach, client: 'Gundlach' },
        { img: Meravis, client: 'Meravis' },
        { img: VdW, client: 'VdW Niedersachsen' },
        { img: Niedersachsenministerium, client: 'Die Niedersächsisches Ministerium für Wirtschaft, Verkehr, Bauen und Digitalisierung' },
        { img: Bausie, client: 'Bausie' },
        { img: Ostland, client: 'Ostland' },
        { img: DRK, client: 'DRK' }
    ];

    return (
        <div className='clients__slider__wrapper'>
            <div className='clients__container'>
                <h1 className='locations__heading'>{t('ourClients')}</h1>
                <p className='locations__paragraph'>{t('clientsDescription')}</p>
            </div>
            <ClientsSlider slides={slides} />
        </div>
    )
}

export default ClientsSection